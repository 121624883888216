import { useFormikContext } from 'formik';
import { css } from '@emotion/css/macro';
import React from 'react';
import { Delete, UploadFile } from '@mui/icons-material';
import Button from '@material-ui/core/Button';
import { TextField } from '../../formaggio';
import { useToggle } from '../../core/hooks';
import { FieldSet, IconButton } from '../../core/components';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import ProjectSampleSets from '../sampleSets/ProjectSampleSets';
import SampleSetData from '../sampleSets/SampleSetData';

const fieldSetClass = css`
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

function ReferencedSampleSet({ sampleSet = {}, unattachSampleSet, index }: any) {
	const clickUnattach = React.useCallback(
		() => unattachSampleSet(index),
		[index, unattachSampleSet],
	);

	return (
		<>
			<FieldSet legend="Sample Set" className={fieldSetClass}>
				<SampleSetData {...sampleSet} />
				<IconButton size="small" color="secondary" onClick={clickUnattach}>
					<Delete />
				</IconButton>
			</FieldSet>
		</>
	);
}

const sampleSetButtonClass = css`
	margin-right: 8px !important;
`;

export default function SystemEvidenceAddUpdateForm() {
	const { values, setFieldValue } = useFormikContext<any>();
	const { sampleSets = [] } = values;
	const { isOn, toggleOff, toggleOn } = useToggle();

	const unattachSampleSet = React.useCallback(
		(index: number) => {
			const newSampleSets = [...sampleSets];
			newSampleSets.splice(index, 1);

			setFieldValue('sampleSets', newSampleSets);
		},
		[sampleSets, setFieldValue],
	);
	const renderSampleSets = React.useMemo(
		() =>
			sampleSets.map((sample: any, index: number) => (
				<ReferencedSampleSet
					sampleSet={sample}
					unattachSampleSet={unattachSampleSet}
					index={index}
					key={index}
				/>
			)),
		[sampleSets, unattachSampleSet],
	);

	return (
		<>
			<TextField fullWidth multiline label="System Components Reviewed" name="componentsReviewed" />
			<TextField fullWidth multiline label="Brief description of the evidence" name="description" />
			<TextField fullWidth multiline label="Title of Workpaper" name="workpaperTitle" />
			<Button
				startIcon={<UploadFile />}
				size="small"
				onClick={toggleOn}
				className={sampleSetButtonClass}
			>
				Attach Sample Set
			</Button>
			{renderSampleSets}
			<MaterialFlyOut open={isOn} onClose={toggleOff} title="System evidence - Link sample set">
				<ProjectSampleSets isForReference onSelected={toggleOff} />
			</MaterialFlyOut>
		</>
	);
}
